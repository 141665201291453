// extracted by mini-css-extract-plugin
export var Chip = "index-module--Chip--52353";
export var Divider = "index-module--Divider--04198";
export var LoyaltyProfileContainer = "index-module--LoyaltyProfileContainer--062a7";
export var MemberSince = "index-module--MemberSince--1c762";
export var StatLabel = "index-module--StatLabel--0ac71";
export var StatRecord = "index-module--StatRecord--ae33f";
export var StatValue = "index-module--StatValue--a6888";
export var TiersChart = "index-module--TiersChart--24586";
export var UserDetails = "index-module--UserDetails--d8687";
export var UserGroups = "index-module--UserGroups--8cd69";
export var UserStats = "index-module--UserStats--b1066";