import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Collapse,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  Typography,
} from "@mui/material";
import { Grade, ExpandLess, ExpandMore } from "@mui/icons-material";
import { getPointsBalanceFromUser } from "../../logic/user/getters/getPointsBalanceFromUser";
import { get } from "lodash";
import { cdnRoot } from "../../utils/media";

const CustomProgressBar = ({ pointBankCoupons, user, appStyles }) => {
  const marks = pointBankCoupons.map(({ price }) => ({
    value: price,
    label: price,
  }));
  const rewardPointsBalance = getPointsBalanceFromUser(user);

  const ratio = (pointBankCoupons && pointBankCoupons.length > 0 && pointBankCoupons[pointBankCoupons.length - 1].price > 0)  ? 100 / pointBankCoupons.length : 0;

  const currentRewardPointBalanceMark = (rewardPointsBalance && ratio > 0) ? rewardPointsBalance * (pointBankCoupons.length / pointBankCoupons[pointBankCoupons.length - 1].price) : 0;
  return (
    <Box sx={{ position: "relative", width: "100%", minHeight: "35px" }}>
      <LinearProgress
        variant="determinate"
        value={currentRewardPointBalanceMark * ratio}
        sx={{
          width: "100%",
          height: 10,
          borderRadius: 5,
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: appStyles.accentColor + 80,
          },
          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: appStyles.actionColor,
          },
        }}
      />
      {marks.map((mark, index) => (
        <Box
          key={mark.value}
          sx={{
            position: "absolute",
            top: 0,
            left: `${(index + 1) * ratio}%`,
            transform: "translateX(-50%)",
          }}
        >
          <Box
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              top: "-12px",
              p: "5px",
              boxSizing: "border-box",
            }}
          >
            {/* Circle Marker */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "20px",
                width: "20px",
                borderRadius: "50%",
                backgroundColor:
                  mark.value <= rewardPointsBalance
                    ? appStyles.actionColor
                    : appStyles.backgroundColor,
                border:
                  mark.value <= rewardPointsBalance
                    ? "2px solid " + appStyles.backgroundColor
                    : "2px solid " + appStyles.actionColor,
              }}
            >
              {mark.value <= rewardPointsBalance && (
                <Grade
                  sx={{
                    height: "18px",
                    width: "18px",
                    color: appStyles.accentColor,
                  }}
                />
              )}
            </Box>
            {/* Vertical Line */}
            <Box
              sx={{
                height: "10px",
                width: "2px",
                backgroundColor: appStyles.actionColor,
              }}
            />
            {/* Label */}
            <Typography variant="body2" sx={{ mt: "5px"}} style={{transform: "rotate(-45deg)", fontSize: "10px", fontWeight: "bolder"}}>
              {mark.label}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const RewardPointsTracker = ({
  pointBankCoupons,
  user,
  appStyles,
  isMobile,
  T
}) => {
  const rewardPointsBalance = getPointsBalanceFromUser(user);
  const [expanded, setExpanded] = useState(false);

  const descriptionImageKey = get(
    appStyles,
    "rewardPointsTracker.rewardPointsTrackerDescriptionImageKey"
  );

  return (
    <Box sx={{ margin: "8px auto", width: isMobile ? "100%" : "50%" }}>
      <Card sx={{ borderRadius: "10px" }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Typography variant="h4" component="span">
                {rewardPointsBalance}
              </Typography>
              <Typography
                variant="body1"
                component="span"
                sx={{ padding: "0 5px" }}
              >
                points
              </Typography>
            </Box>
            {descriptionImageKey && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                }}
                onClick={() => setExpanded((state) => !state)}
              >
                <Typography
                  variant="body2"
                  component="span"

                >
                  {T("Rewards options")}
                </Typography>
                <IconButton
                  size="small"
                  style={{ marginTop: "-20px" }}
                >
                  {!expanded ? <ExpandMore /> : <ExpandLess />}
                </IconButton>
              </Box>
            )}
          </Box>
          <Box sx={{ margin: "10px" }}>
            <CustomProgressBar
              pointBankCoupons={pointBankCoupons}
              user={user}
              appStyles={appStyles}
            />
          </Box>
        </CardContent>
        {descriptionImageKey && (
          <Collapse in={expanded} timeout="auto">
            <CardMedia
              //TODO: update height for description image block
              style={{
                pointerEvents: "none",
                objectFit: "cover",
                width: "100%",
              }}
              image={`${cdnRoot}/${descriptionImageKey}`}
              component="img"
              alt="Description image"
            />
          </Collapse>
        )}
      </Card>
    </Box>
  );
};

export default RewardPointsTracker;
