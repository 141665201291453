import _ from "lodash";
import React from "react";
import Modal from "../Modal";
import { getParams, navigateTo, setParams } from "../../utils/location";
import AppContainer from "../AppContainer";
import Button from "../Button";
import CloseIcon from "../icons/Close.svg";
import { default as GiftEmptyStateIcon, default as GiftIcon } from "../icons/Gift.svg";
import ShareIcon from "../icons/Share.svg";
import { PhoneInput } from "../Inputs";
import Item from "../Item";
import List from "../List";
import Loader from "../Loader";
import LoyaltyProfile from "../LoyaltyProfile";
import NewOrPreviousOrderModal from "../NewOrPreviousOrderModal";
import UnlockRewardModal from "../UnlockRewardModal";
import * as styles from "./index.module.scss";
import {getPointsBalanceFromUser} from "../../logic/user/getters/getPointsBalanceFromUser";

const PHONE_NUMBER = "PHONE_NUMBER";

const getPredictedGift = (loyaltyProfile) => {
  const loadLoyaltyProfileData = _.get(loyaltyProfile, "data");
  if (!loadLoyaltyProfileData) {
    return null;
  }
  const accumulativeCouponPoilcyRemainders = _.get(
    loadLoyaltyProfileData,
    "accumulativeCouponPoilcyRemainders",
  );
  const moneyCoupons = _.sortBy(
    _.filter(
      accumulativeCouponPoilcyRemainders,
      ({ type, remainder }) => type === "MONEY" && remainder,
    ),
    "remainder",
  );
  return _.first(moneyCoupons);
};

export default class GiftsView extends React.Component {
  state = {
    giftShareInputOpen: null,
    giftSelected: null,
    unlockRewardModalOpen: false,
  };
  inputRefs = {};
  itemToScrollRef = null;

  componentDidMount() {
    if (this.props.user.userId) {
      this.props.loadGifts();
    }
    this.props.resetShareGift();

    this.isUnlockRewardEnabled = _.get(this.props, "pageContext.businessAppConfiguration.enableUnlockRewardsFlow");
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    
    if (!prevProps.user.loggedIn && user.loggedIn && user.userId) {
      this.props.loadGifts();
    }

    if (
      prevProps.user.loadingGifts &&
      !this.props.user.loadingGifts &&
      !this.props.errorLoadingGifts
    ) {
      this.props.resetGiftsUnseenAmount();
      _.map(this.props.user.gifts, (gift, index) => {
        if (gift.couponInstance && !gift.couponInstance.firstSeenDate) {
          this.props.markGiftSeen(gift.couponInstance.id);
        }
      });

      this.itemToScrollRef &&
        this.itemToScrollRef.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start"
        });
    }
  }

  shareGift = (giftId) => () => {
    const { location } = this.props;
    this.props.selectGiftForShare(giftId);
    window.isNativeApp
      ? navigateTo(setParams("/contacts", getParams(location)))
      : this.setState({ giftShareInputOpen: giftId });
  };

  registerInput = (key) => (ref) => {
    this.inputRefs[key] = ref;
  };

  onSubmit = () => {
    const { location } = this.props;
    this.inputRefs[PHONE_NUMBER].validate((error, phoneNumber) => {
      if (!error) {
        this.props.selectContactToShareGift({ phoneNumber });
        this.props.shareGiftDesktop();
        navigateTo(setParams("/share", getParams(location)));
      }
    });
  };

  closeGiftShareInput = (giftId) => () =>
    this.setState({ giftShareInputOpen: null });

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.giftRedeem.shouldNavigateTo &&
      this.props.giftRedeem.shouldNavigateTo !==
        nextProps.giftRedeem.shouldNavigateTo
    ) {
      navigateTo(nextProps.giftRedeem.shouldNavigateTo);
    }
  }

  resetOrderBranchAndGoToFindLocation = () => {
    this.props.resetBranchId();
    navigateTo("/find-location");
  };

  handleGiftItemButtonClick = (gift) => {
    if(!this.isUnlockRewardEnabled) {
      this.handleRedeemGiftClick(gift)();
      return;
    }

    this.setState({ unlockRewardModalOpen: true, giftSelected: gift });
  }

  onSuccessfulUnlock = () => {
    this.setState({ unlockRewardModalOpen: false });
    this.props.loadGifts();
  }

  handleRedeemGiftClick = (gift) => () => {
    const {
      order: { orderItems },
      location,
    } = this.props;

    const isDuringOrder = !_.isEmpty(orderItems);
    if (isDuringOrder) {
      if (getParams(location).shouldNotAskToContinueOrder) {
        this.props.selectGift(gift);
      } else {
        this.setState({ giftSelected: gift });
        this.props.openNewOrPrevOrderModal();
      }
    } else {
      this.props.startNewOrder();
      this.props.selectGift(gift);
    }
  };


  onUserConfirmStartNewOrder = () => {
    this.props.closeNewOrPrevOrderModal();
    this.props.startNewOrder();
    this.props.selectGift(this.state.giftSelected);
  };

  render() {
    const {
      appStyles,
      pageContext: {
        menuData,
        business,
        businessAppConfiguration,
        servingOptions,
        branches,
        pointBankCoupons,
      },
      location,
      header,
      user,
      data,
      giftRedeem,
      currentBranch,
      order: { servingOptionType, branchId },
      T,
      loadLoyaltyProfile,
      app
    } = this.props;
    
    const { areaCode } = business;
    const disableRedeem = _.includes(appStyles.disabledFeatures, "order");

    if (user.loadingGifts) {
      return (
        <AppContainer.Content tightBottom appStyles={appStyles}>
          <AppContainer.CenteredColumn>
            <Loader appStyles={appStyles} classNames={styles.Loader} />
            <strong>{T("Loading your rewards...")}</strong>
          </AppContainer.CenteredColumn>
        </AppContainer.Content>
      );
    }

    const loginTarget = user.loggedIn
      ? null
      : setParams("/login", { onCloseTarget: header.onCloseTarget });
    const orderSelectedServingOption =
      _.find(servingOptions, { type: servingOptionType }) || {};
    const orderSelectedBranch = _.find(branches, { id: branchId }) || {};
    const rewardPointsBalance = getPointsBalanceFromUser(user);

    const userGroupIds = _.get(user, "loyaltyProfile.data.groupIds");

    const isCouponAvaialbleForGroup = (couponInstance) => {
      const { groupIds, notGroupIds } = couponInstance;

      const isAvailable = Boolean(
        (_.isEmpty(groupIds) ||
          _.intersection(groupIds, userGroupIds).length) &&
          (!userGroupIds || !_.intersection(notGroupIds, userGroupIds).length),
      );

      return isAvailable;
    };

    const isAvailablePointBankCoupon = (pointBankCoupon) => {
      return rewardPointsBalance - (pointBankCoupon.price || 0) >= 0;
    };

    const [availablePointBankCoupons, lockedPointBankCoupons] = _.partition(
      _.filter(pointBankCoupons, isCouponAvaialbleForGroup),
      isAvailablePointBankCoupon,
    );

    const predictedGift = getPredictedGift(_.get(user, "loyaltyProfile"));
    const iconStyle = { margin: "0 4px", width: appStyles.baseFontSize || 16 };

    const GiftActionPanel = ({ gift }) =>
      this.state.giftShareInputOpen === gift.couponInstance.id ? (
        <List.Card.Actions>
          <PhoneInput
            appStyles={appStyles}
            refEl={this.registerInput(PHONE_NUMBER)}
            placeholder={T("Phone Number")}
            areaCode={areaCode}
            locale={appStyles.locale}
            withButtons
          >
            <Button appStyles={appStyles} centered onClick={this.onSubmit}>
              {T("Share")}
              <ShareIcon style={iconStyle} />
            </Button>
            <Button
              appStyles={appStyles}
              centered
              onClick={this.closeGiftShareInput(gift.couponInstance.id)}
              classNames={styles.ShareCloseInputButton}
              label={T("Close")}
            >
              <CloseIcon />
            </Button>
          </PhoneInput>
        </List.Card.Actions>
      ) : (
        <List.Card.Actions
          style={
            !_.isEmpty(this.state.items) &&
            _.find(
              interpolatedStyles,
              (item) => item.key === gift.couponInstance.id,
            ).style
          }
        >
          {gift.couponInstance.coupon.isShareable &&
           (!_.get(gift, "couponInstance.coupon.isShareableOnlyToNewUsers") || !gift.couponInstance.redeemableForUser) &&
           (<Button
              appStyles={appStyles}
              centered
              marginTop
              onClick={this.shareGift(gift.couponInstance.id)}
              style={{ margin: 5 }}
            >
              {_.get(gift, "couponInstance.coupon.isShareableOnlyToNewUsers") ? T("Refer a friend") : T("Share")}
              <ShareIcon style={iconStyle} />
            </Button>
          )}
          {businessAppConfiguration.hasDonations &&
            gift.couponInstance.coupon.isDonateable && (
              <Button
                appStyles={appStyles}
                centered
                marginTop
                onClick={() =>
                  this.props.selectGiftForDonation(gift.couponInstance.id)
                }
                to={setParams(`/charities`, getParams(location))}
                style={{ margin: 5 }}
              >
                {T("Donate")}
              </Button>
            )}
          {(gift.couponInstance.coupon.shareableOnlyForOriginalUser ===
            undefined ||
            !gift.couponInstance.coupon.shareableOnlyForOriginalUser ||
            gift.couponInstance.redeemableForUser) &&
            !disableRedeem && (
              <Button
                appStyles={appStyles}
                marginTop
                centered
                onClick={this.handleRedeemGiftClick(gift)}
                style={{ margin: 5 }}
              >
                {T("Redeem")}
                <GiftIcon style={iconStyle} />
              </Button>
            )}
        </List.Card.Actions>
      );

    const PointsCouponActionPanel = ({ pointsCoupon }) => (
      <List.Card.Actions>
        {disableRedeem ? (
          <div
            style={{
              padding: 10,
              boxShadow: "0 0 8px #aaa",
              backgroundColor: "rgba(0,0,0,0.1)",
              borderRadius: appStyles.cardBorderRadius,
            }}
          >
            <strong style={{ color: appStyles.accentColor }}>
              {T("Redeem your Points at the Restaurant")}
            </strong>
          </div>
        ) : (
          <Button
            appStyles={appStyles}
            marginTop
            centered
            onClick={() => this.handleGiftItemButtonClick(pointsCoupon)}
            style={{ margin: 5 }}
          >
            {T(this.isUnlockRewardEnabled ? "Unlock for" : "Redeem for")} {pointsCoupon.price} {T("Points")}
            <GiftIcon style={iconStyle} />
          </Button>
        )}
      </List.Card.Actions>
    );

    const GiftItem = ({ gift, pointsCoupon, locked, lockDescription }) => {
      if (pointsCoupon) {
        return (
          <Item
            T={T}
            isGiftItem
            giftPrice={pointsCoupon.price}
            appStyles={appStyles}
            description={T(pointsCoupon.coupon.description)}
            title={pointsCoupon.coupon.title}
            imageKey={pointsCoupon.coupon.imageKey}
            imagePreview={pointsCoupon.coupon.imagePreview}
            isFlexColumn={true}
            lockDescription={lockDescription}
            isLocked={locked}
            classNames={styles.Gift}
            expirationDate={pointsCoupon.coupon.couponValidity.expireDate}
            actionsRenderer={
              !locked &&
              (() => <PointsCouponActionPanel pointsCoupon={pointsCoupon} />)
            }
          />
        );
      }

      return (
        <Item
          T={T}
          isGiftItem
          appStyles={appStyles}
          imageByUrl
          description={T(gift.couponInstance.coupon.description)}
          title={gift.couponInstance.coupon.title}
          image={
            gift &&
            gift.couponImagePath &&
            _.replace(
              gift.couponImagePath.url,
              new RegExp("http://", "g"),
              "https://"
            )
          }
          isNew={!gift.wasSeen}
          isImageByUrl
          isFlexColumn
          classNames={styles.Gift}
          expirationDate={gift.expirationDate}
          actionsRenderer={() => <GiftActionPanel gift={gift} />}
          itemToAnimate={
            !gift.wasSeen &&
            gift.couponInstance.couponId ===
              this.state.giftSelected?.couponId
          }
          shouldAnimateCard={
            !gift.wasSeen &&
            gift.couponInstance.couponId ===
              this.state.giftSelected?.couponId
          }
          refEl={(ref) => {
            if (
              !gift.wasSeen &&
              gift.couponInstance.couponId ===
                this.state.giftSelected?.couponId
            ) {
              this.itemToScrollRef = ref;
            }
          }}
        />
      );
    };
    return (
      <AppContainer.Content
        tightBottom
        appStyles={appStyles}
      >
        {this.state.giftSelected && this.state.unlockRewardModalOpen && (
          <UnlockRewardModal
            modalOpen={this.state.unlockRewardModalOpen}
            closeModal={() =>
              this.setState({
                unlockRewardModalOpen: !this.state.unlockRewardModalOpen,
              })
            }
            T={T}
            appStyles={appStyles}
            giftSelected={this.state.giftSelected}
            onSuccessfulUnlock={this.onSuccessfulUnlock}
          />
        )}
        <NewOrPreviousOrderModal
          T={T}
          appStyles={appStyles}
          onChooseNewOrder={this.onUserConfirmStartNewOrder}
          onChooseContinueOrder={() =>{
              this.props.closeNewOrPrevOrderModal();
              this.props.selectGift(this.state.giftSelected)
            }
          }
          modalOpen={app.isOpenNewOrPrevOrderModal}
          closeModal={this.props.closeNewOrPrevOrderModal}
          orderSelectedServingOption={orderSelectedServingOption}
          orderSelectedBranch={orderSelectedBranch}
        />
        <Modal
          open={this.props.giftRedeem.pickAnotherLocationOrDismiss}
          onClose={this.props.resetGiftRedeem}
          appStyles={appStyles}
          focusTrapped
        >
          <div style={{ padding: 20 }}>
            <h2
              style={{
                ...appStyles.StartNewOrderPopupTitle,
                color: appStyles.accentColor,
                textAlign: "center",
              }}
              role="heading"
              aria-level="1"
            >
              {currentBranch
                ? `${T("This reward is not available at")} ${currentBranch.name}`
                : T("Please Select a  different location first")}
            </h2>
            <p style={{ textAlign: "center" }}>{T("")}</p>
            <div>
              <Button
                appStyles={appStyles}
                centered
                style={{ marginBottom: 20 }}
                onClick={this.props.resetGiftRedeem}
              >
                {T("Next Time...")}
              </Button>
              <Button
                linkStyle
                appStyles={appStyles}
                style={{ paddingBottom: 10, paddingTop: 10 }}
                centered
                onClick={this.resetOrderBranchAndGoToFindLocation}
              >
                {T("Change Location")}
              </Button>
            </div>
          </div>
        </Modal>
        {businessAppConfiguration.hasPointBank && (
          <LoyaltyProfile
            user={user}
            business={business}
            appStyles={appStyles}
            businessAppConfiguration={businessAppConfiguration}
            T={T}
            loadLoyaltyProfile={loadLoyaltyProfile}
            pointBankCoupons={pointBankCoupons}
            app={app}
          />
        )}

        {_.isEmpty(user.gifts) &&
        !predictedGift &&
        _.isEmpty(availablePointBankCoupons)  &&
        _.isEmpty(lockedPointBankCoupons)? (
          [
            <AppContainer.CenteredColumn>
              <div
                style={{ marginTop: 20, color: appStyles.accentColor }}
                className={styles.GiftIconWrapper}
              >
                <GiftEmptyStateIcon />
              </div>
            </AppContainer.CenteredColumn>,
            <AppContainer.CenteredColumn>
              <p style={{ color: "#a9a9a9" }}>
                {T(
                  "Take a peek once in a while… A new reward is just around the corner!",
                )}
              </p>
            </AppContainer.CenteredColumn>,
          ]
        ) : (
          <List classNames={styles.Gifts}>
            {_.map(user.gifts, (gift, index) => (
              <GiftItem key={index} gift={gift} />
            ))}
            {_.map(availablePointBankCoupons, (pointsCoupon) => (
              <GiftItem
                key={pointsCoupon.couponId}
                pointsCoupon={pointsCoupon}
              />
            ))}
            {_.map(lockedPointBankCoupons, (pointsCoupon) => (
              <GiftItem
                key={pointsCoupon.couponId}
                pointsCoupon={pointsCoupon}
                locked
                lockDescription={`${T("Collect just")} ${((pointsCoupon.price ||
                  0) - rewardPointsBalance).toFixed(0)} ${T(
                  "more points and make this available to you!",
                )}`}
              />
            ))}
            {predictedGift && predictedGift.coupon && (
              <Item
                T={T}
                isGiftItem={true}
                appStyles={appStyles}
                key={predictedGift.coupon.id}
                description={T(predictedGift.coupon.description)}
                title={predictedGift.coupon.title}
                image={`url(${_.get(predictedGift, "couponImagePath.url")})`}
                isImageByUrl
                isLocked
                lockDescription={`${T("Spend just")} ${
                  business.currencySymbol
                } ${_.isNumber(predictedGift.remainder) &&
                  predictedGift.remainder.toFixed(2)} ${T(
                  "more and make this available to you!",
                )}`}
                classNames={styles.Gift}
                expirationDate={_.get(
                  predictedGift,
                  "couponValidity.expireDate",
                )}
              />
            )}
          </List>
        )}
      </AppContainer.Content>
    );
  }
}
