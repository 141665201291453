import React from "react";
import { Box, Card, Divider, Grid, Typography } from "@mui/material";
import { mediaTypes } from "../../utils/media";
import Image from "../image";
import RichText from "../rich-text";
import { get } from "lodash";

const Banner = ({
  storeCreditBalance = 0,
  appStyles,
  isMobile,
  T,
  business: { currencySymbol },
}) => {
  const {
    rewardBanner: {
      rewardBannerDescription,
      rewardBannerTitle,
      rewardBannerBackground,
      rewardBannerIconImageKey,
    },
    RewardBannerDescriptionText,
    RewardBannerTitle,
    RewardBannerAmount,
  } = appStyles;

  return (
    <Box sx={{ margin: "8px auto", width: isMobile ? "100%" : "50%" }}>
      <Card
        sx={{
          background: rewardBannerBackground && (rewardBannerBackground.startsWith("#")
            ? rewardBannerBackground
            : `url(${rewardBannerBackground})`),
          padding: 1,
          borderRadius: 2,
        }}
      >
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3.5}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                component="span"
                sx={{
                  fontWeight: "bold",
                  lineHeight: 1,
                  ...RewardBannerAmount,
                }}
              >
                {currencySymbol}
                { storeCreditBalance}
              </Typography>
            </Box>
            <Typography
              variant="body2"
              component="span"
              sx={RewardBannerDescriptionText}
              style={{ width:"100%",  display:"flex", justifyContent: get(RewardBannerDescriptionText, "textAlign")  || "center"}}
            >
              {T("AVAILABLE FUNDS")}
            </Typography>
          </Grid>
          <Grid item xs={2.5}>
            {rewardBannerIconImageKey && (
              <Image
                mediaKey={rewardBannerIconImageKey}
                mediaType={mediaTypes.image}
                sizes={"100px"}
                alt=""
                wrapperStyle={{ width: 60, height: 80 }}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                justifyContent: "center",
              }}
            >
              <Typography
                component="span"
                sx={{ fontWeight: "bold", ...RewardBannerTitle }}
              >
                {rewardBannerTitle}
              </Typography>
            </Box>
            <Divider
              sx={{
                borderBottomWidth: 1,
                borderColor: appStyles.RewardBannerDescriptionText && appStyles.RewardBannerDescriptionText.color,
              }}
            />
            <RichText
              styles={{
                "& p": { margin: "0 !important" },
                ...appStyles.RewardBannerDescriptionText,
              }}
            >
              {rewardBannerDescription}
            </RichText>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default Banner;
