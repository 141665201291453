// extracted by mini-css-extract-plugin
export var Gift = "index-module--Gift--75e27";
export var GiftIconWrapper = "index-module--GiftIconWrapper--99562";
export var Gifts = "index-module--Gifts--5ad29";
export var Loader = "index-module--Loader--9b4b3";
export var LocationImageCaption = "index-module--LocationImageCaption--8476c";
export var LocationImageWrapper = "index-module--LocationImageWrapper--54eb0";
export var LockedGiftIcon = "index-module--LockedGiftIcon--d0cd6";
export var NewGift = "index-module--NewGift--8d83d";
export var ShareCloseInputButton = "index-module--ShareCloseInputButton--c48f9";
export var fadeScaleIn = "index-module--fadeScaleIn--85b70";
export var scale = "index-module--scale--12cf0";